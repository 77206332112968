import React, {useState} from 'react';
import {Layout, TabPane, Tabs, Empty, Input} from '@douyinfe/semi-ui';
import {IconBytedanceLogo, IconSearch} from '@douyinfe/semi-icons';
import TopNav from "../component/TopNav";
import {IllustrationConstruction,} from '@douyinfe/semi-illustrations';
import ContentCard from "../component/ContentCard";
import {Link} from 'react-router-dom';
import TopCarousel from "../component/TopCarousel";

function HomePage() {
    const { Header, Footer, Content } = Layout;
    const [mode, setMode] = useState('semi-always-light');
    const switchMode = () => {
        const newMode = mode === 'semi-always-dark' ? 'semi-always-light' : 'semi-always-dark';
        setMode(newMode);
    };
    const items = [
        {text: 'Nature',content: <Empty image={<IllustrationConstruction style={{ width: "100%", height: 1000 }} />} title={'功能建设中'} description="当前功能暂未开放，敬请期待。"/>},
        {text: 'Wallpapers', content: <Empty image={<IllustrationConstruction style={{ width: "100%", height: 1000 }} />} title={'功能建设中'} description="当前功能暂未开放，敬请期待。"/>},
        {text: 'Food & Drink',content: <Empty image={<IllustrationConstruction style={{ width: "100%", height: 1000 }} />} title={'功能建设中'} description="当前功能暂未开放，敬请期待。"/>},
        {text: 'Street Photography',content: <Empty image={<IllustrationConstruction style={{ width: "100%", height: 1000 }} />} title={'功能建设中'} description="当前功能暂未开放，敬请期待。"/>},
        {text: 'Architecture & Interiors',content: <Empty image={<IllustrationConstruction style={{ width: "100%", height: 1000 }} />} title={'功能建设中'} description="当前功能暂未开放，敬请期待。"/>},
    ]

    return (
        <div className={mode}>
            <Layout style={{ border: '1px solid var(--semi-color-border)', display: "grid"}}>
                <Header style={{ backgroundColor: 'var(--semi-color-bg-0)' }}>
                    <TopNav mode={mode} switchMode={switchMode} />
                </Header>
                <Layout>
                    <Content style={{padding: '14px', backgroundColor: 'var(--semi-color-bg-0)',}}>
                        <Tabs style={{width: '100%', backgroundColor: 'var(--semi-color-bg-0)'}}
                              type="line"
                              collapsible>
                            <TopCarousel />
                            {items.map((item, index) => (
                                <TabPane tab={<div style={{ color:'var(--semi-color-text-2)',fontWeight:'lighter'}}>{item.text}</div>} itemKey={item.text} key={item.text}>
                                    {item.content}
                                </TabPane>))}
                        </Tabs>
                    </Content>
                </Layout>

                <Footer style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', color: 'var(--semi-color-text-2)', backgroundColor: 'rgba(var(--semi-grey-0), 1)' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Copyright © <Link to={"https://beian.miit.gov.cn/"}>粤ICP备2021047821号-2</Link> </span>
                    </span>
                    <span>
                        <span style={{ marginRight: '24px' }}>2024 OK喔像素故事</span>
                        <span>All Rights Reserved.</span>
                    </span>
                </Footer>
            </Layout>
        </div>
    );
}

export default HomePage;


