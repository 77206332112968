import React from 'react';
import {Card, Carousel} from '@douyinfe/semi-ui';
import { IconArrowLeft, IconArrowRight } from "@douyinfe/semi-icons";

class TopCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.imgList = [
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_0.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_1.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_2.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_3.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_4.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_5.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_6.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_7.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_8.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_9.png",
            "http://47.120.10.30:9876/img/1/%E7%89%B9%E5%88%AB%E7%9A%84%E7%A4%BC%E7%89%A9_10.png"
        ];
        this.state = {
            activeIndex: 0,
        };
        this.arrowProps = {
            leftArrow: { children: null },
            rightArrow: { children: null },
        };
    }

    onChange(activeIndex) {
        this.setState({ activeIndex });
    }

    render() {
        const style = {
            width: '100%',
            height: '40vh',
        };

        const { activeIndex } = this.state;

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Carousel style={style}
                          // activeIndex={activeIndex}
                          speed={1000}
                          indicatorType={""}
                          animation='fade'
                          autoPlay={{ interval: 3000, hoverToPause: true }}
                          theme='light'
                          onChange={this.onChange.bind(this)}
                          arrowProps={this.arrowProps}>
                    {
                        this.imgList.map((src, index) => {
                            return (
                                <div key={index}
                                     style={{
                                         backgroundSize: "cover",
                                         backgroundImage: `url(${src})`,
                                         backgroundPosition: 'center',
                                         backgroundRepeat: 'no-repeat',
                                     }}></div>
                            );
                        })
                    }
                </Carousel>
            </div>
        );
    }
}

export default TopCarousel;
