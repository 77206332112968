import './App.css';
import { BrowserRouter  as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./page/HomePage";

function App() {

    return (
        <Router basename={"/"}>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
