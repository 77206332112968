import React from 'react';
import {Nav, Button, Avatar, Input, Tabs, Divider, Dropdown, DropdownMenu, DropdownDivider} from '@douyinfe/semi-ui';
import {IconBell, IconHelpCircle, IconSearch, IconMenu} from '@douyinfe/semi-icons';
import {Link} from 'react-router-dom';

const TopNav = ({mode, switchMode}) => {
    const logoSrc = mode === 'semi-always-dark' ? 'logo-dark.png' : 'logo-light.png';
    const keys = [
        {text: 'Explore', path: '/1', iconSrc: mode === 'semi-always-dark' ? 'explore-dark.png' : 'explore-light.png'},
        {text: 'Help', path: '/', iconSrc: mode === 'semi-always-dark' ? 'help-dark.png' : 'help-light.png'},
        {text: 'About', path: '/', iconSrc: mode === 'semi-always-dark' ? 'about-dark.png' : 'about-light.png'},
        {text: 'Submit', path: '/', iconSrc: mode === 'semi-always-dark' ? 'submit-dark.png' : 'submit-light.png'},
    ]

    const items = keys.map((key, index) => (
        <Dropdown.Item key={index} type="tertiary" style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img style={{ height: '30px', alignSelf: 'center' }} src={require(`../img/${key.iconSrc}`)} alt="Icon" />
                <Link to={key.path} style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    textDecoration: 'none',
                    fontStyle: 'normal',
                    color: 'var(--semi-color-text-2)',
                    fontWeight: 400,
                    fontSize: 15,
                    fontFamily: 'Minecraft',
                    alignSelf: 'center', // 水平居中
                }}>
                    {key.text}
                </Link>
                {index !== keys.length - 1 && <DropdownDivider />}            </div>
        </Dropdown.Item>
    ));

    return (
        <Nav mode={'horizontal'}>
            <Nav.Header>
                <p style={{
                    fontFamily: 'Minecraft',
                    fontSize: 30,
                }}>OKWO.TOP</p>
                {/*<img*/}
                {/*    src={require(`../img/${logoSrc}`)} // Adjust the path as necessary*/}
                {/*    alt="Switch Mode"*/}
                {/*    onClick={switchMode}*/}
                {/*    style={{*/}
                {/*        width: '120px',*/}
                {/*        height: '40px',*/}
                {/*        margin: '0 0 0 0px',*/}
                {/*        padding: '0 0px',*/}
                {/*        cursor: 'pointer',*/}
                {/*    }}*/}
                {/*/>*/}
            </Nav.Header>
            <Nav.Footer>
                <Input prefix={<IconSearch/>} showClear size={"large"}></Input>
                <Divider layout="vertical" margin='12px'/>
                <Dropdown trigger={'click'} showTick position={'bottomRight'} render={<DropdownMenu>{items}</DropdownMenu>}>
                    {/*<Button style={{background: 'transparent'}} icon={<img style={{height: '30px', alignSelf: 'center'}} src={require(mode === 'semi-always-dark' ? '../img/menu-dark.png' : '../img/menu-light.png')}/>} size={'large'}></Button>*/}
                    <Button style={{background: 'transparent'}} icon={<IconMenu style={{}}/>}></Button>
                </Dropdown>
            </Nav.Footer>
        </Nav>
    );
};

export default TopNav;
